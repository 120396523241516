import React from 'react'

function Nomatch() {
  return (
    <div>
        <h1 style={{color
        :"#143566", fontsize:"30px"}}>This page doesn't exist </h1>
    </div>
  )
}

export default Nomatch