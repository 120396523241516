import React from 'react'

import "./happ2.css"
function AppointProduct() {
  return (
    <section className='happ2'>
      <a href="/cardiocamp">
      <img src="./happ2.png" alt=""/>
      </a>
    </section>
  )
}

export default AppointProduct
